.DynamicTiles {
  width: 100%;
}

.DynamicTiles-wrapper {
  width: 100%;
  display: block;
}

.DynamicTiles h1,
.DynamicTiles h2 {
  color: black;
}
