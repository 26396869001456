.vertical-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    background: none;
}

.vertical-wrapper {
    position: relative;
    height: auto;
    background: none;
}

.vertical-wrapper.not-visible {
    display: none;
}

.vertical-node {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
}

.vertical-node.align-left {
    text-align: left;
}

.vertical-node.align-center {
    text-align: center;
}

.vertical-node.align-right {
    text-align: right;
}

.vertical-node > * {
    display: inline-flex;
    text-align: left;
}
