.ButtonBar-container {
  width: 100%;
}

.ButtonBar-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    background: none;
    transition: background-color 0.2s ease-in;
    width: inherit;
}

.ButtonBar-wrapper.align-left > * {
  float: left;
  margin-right: 5px;
}

.ButtonBar-wrapper.align-left > *:last-of-type {
  margin-right: 0;
}

.ButtonBar-wrapper.align-right > * {
  float: right;
  margin-left: 5px;
}

.ButtonBar-wrapper.align-right > *:last-of-type {
  margin-left: 0;
}
