.Heading-label {
  color: black;
}

.Text-italic {
  font-style: italic;
}

.Text-bold {
  font-weight: bold;
}

.Text-underline {
  text-decoration: underline;
}

.Text-strikethrough {
  text-decoration: line-through;
}

.Text-redo {
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
