.PopupWrapper-Heading.primary {
    background-color: #004c85;
    color: white;
}

.PopupWrapper-Heading.warning {
    background-color: #eff543;
}

.PopupWrapper-Heading.error {
    background-color: #850704;
    color: white;
}


