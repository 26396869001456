.Tile {
}

.Tile a,
.Tile a:hover,
.Tile a:focus {
  color: white;
  text-decoration: none;
  transition: all 0.3s;
  word-wrap: break-word;
}

.Tile a[data-toggle="collapse"] {
  position: relative;
}

.Tile a[aria-expanded="false"]::before,
.Tile a[aria-expanded="true"]::before {
  content: "\f107";
  display: block;
  position: absolute;
  right: 20px;
  font-family: FontAwesome;
  font-size: 20px;
}

.Tile a[aria-expanded="true"]::before {
  content: "\f106 ";
}

.Tile a {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a7fae+0,708bae+100&0.8+0,0.8+100 */
  background: #5a7fae;
  /*
  background: -moz-linear-gradient(
    -45deg,
    rgba(90, 127, 174, 0.8) 0%,
    rgba(112, 139, 174, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(90, 127, 174, 0.8) 0%,
    rgba(112, 139, 174, 0.8) 100%
  ); 
  background: linear-gradient(
    135deg,
    rgba(90, 127, 174, 0.8) 0%,
    rgba(112, 139, 174, 0.8) 100%
  ); 
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc5a7fae', endColorstr='#cc708bae', GradientType=1);
  */
  border-radius: 2px;
  display: block;
  font-size: 28px;
  font-weight: 100;
  margin: 0 0 20px;
  padding: 50px 10px;
  text-align: center;
  transition: all 0.3s;
  color: white;
}

/*.Tile a, .Tile a:hover, .Tile a:focus {
    color: white;
    text-decoration: none;
    transition: all 0.3s;
}*/

.Tile a:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a7fae+0,708bae+100 */
  background: rgb(90, 127, 174); /* Old browsers */
  /*
  background: -moz-linear-gradient(
    -45deg,
    rgba(90, 127, 174, 1) 0%,
    rgba(112, 139, 174, 1) 100%
  ); 
  background: -webkit-linear-gradient(
    -45deg,
    rgba(90, 127, 174, 1) 0%,
    rgba(112, 139, 174, 1) 100%
  ); 
  background: linear-gradient(
    135deg,
    rgba(90, 127, 174, 1) 0%,
    rgba(112, 139, 174, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5a7fae', endColorstr='#708bae', GradientType=1);
  */
  -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.6);
  transform: scale(1.02);
  text-decoration: none;
}
